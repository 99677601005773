import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import React from 'react'
import { Content } from '../components/Content'
import { Intro } from '../components/Intro'
import { MissingContent } from '../components/MissingContent'
import { HomepageSearch } from '../components/search/HomepageSearch'
import { Seo } from '../components/Seo'
import { Spacer } from '../components/Spacer'
import { WidthBound } from '../components/WidthBound'
import { PageFragment } from '../src/model/fragments'
import { DEFAULT_LOCALE, getLocale, getPossibleLocales, Locale } from '../src/model/locale'
import { axiosGet } from '../src/utils/axiosWrapper'

export default function Homepage({
	page,
	possibleLocales,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {

	if (!page) {
		return <MissingContent />
	}

	const data = getLocale(page.locales, possibleLocales)

	if (!data) {
		return <MissingContent />
	}
	const blocks = data.content?.blocks || []
	const otherBlocks = blocks.slice(1)
	const leadBlocks = blocks.slice(0, 1)

	for(const block of leadBlocks)
	{
		block.html = block.html.replace('<p>', '').replace('</p>', '')
	}

	return (
		<>
			<Seo seo={data.seo} />
			<Spacer initial={50} break800={100} />
			<Intro title={data.title}>
				<WidthBound size="narrow">
					<Content content={{ blocks: leadBlocks }} />
					<Spacer initial={30} />
					<HomepageSearch />
				</WidthBound>
			</Intro>

			{/* @TODO: width bound for some blocks */}
			<Content content={{ blocks: otherBlocks }} />
		</>
	)
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
	const locale = (ctx.query.locale || DEFAULT_LOCALE) as Locale
	const possibleLocales = getPossibleLocales(ctx.query.locale as Locale)
	const { getPage: page } = await axiosGet('/layout/page/get', {locale: locale, navigationId: ctx.query.id}) as {
		getPage: PageFragment
	}

	return {
		props: {
			page,
			possibleLocales,
		},
	}
}
