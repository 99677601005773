import React, { MouseEvent } from 'react';
import { useCurrentLocale } from '../../src/contexts/CurrentLanguageContext';
import { axiosGet, axiosPost } from '../../src/utils/axiosWrapper';
import { FulltextHits, FulltextHitsProps } from './FulltextHits'
import { FulltextSearchBox } from './FulltextSearchBox'



export const HomepageSearch: React.FC = () => {
	const locale = useCurrentLocale();
	const [queryString, setQueryString] = React.useState<string>('');
	const [data, setData] = React.useState<FulltextHitsProps | null>(null);

	React.useEffect(() => {

		if(queryString.length >= 3)
		{
			(axiosPost('/fulltext/autocomplete', {q: queryString, locale: locale}) as Promise<FulltextHitsProps>)
			.then(res => setData(res));
		}
		else 
		{
			setData(null);
		}

	}, [queryString]);

	const onHitClick = (e : MouseEvent<HTMLAnchorElement>) => {
		setQueryString('');
	};

	const onBlur = () => {
		setTimeout(() => {
			setQueryString('');
		}, 100);
	};

	return (
		<div className="homepageSearch" >
				<FulltextSearchBox onSubmit={onBlur} queryString={queryString} setQueryString={setQueryString} />
				<div className="homepageSearch-hits" onBlur={onBlur} onClick={onBlur}>
					{
						data && <FulltextHits onHitClick={onHitClick} {...data} />
					}
				</div>
		</div>
	)
}
